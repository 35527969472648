import styled from 'styled-components';

const OpinionStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #f3f3f3;
    background-color: #fbfbfb;
    position: relative;
    transition: all 0.3s;
    top: 0px;
    &:hover{
        box-shadow: 0px 8px 12px rgba(0,0,0,0.2);
        top: -3px;
    }
    .name{
        margin: -20px -20px 20px -20px;
        padding: 20px;
        background-color: #cdde66;
        display: flex;
        flex-direction: row;
        span{
            flex: 1;
            text-align: right;
            color: #768425;
        }
    }
    .stars{
        width: 100px;
    }
    .content{
        padding: 20px 20px 20px 0px;
        color: grey;
    }
`;	

export default OpinionStyle;