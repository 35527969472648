import styled from 'styled-components';

const OpinionsStyle = styled.div`
    flex: 1;
    .mapOpinions{
        height: 400px;
        .markerOpinion{
            display: flex;
            flex-direction: column;
            width: fit-content;
            border-radius: 5px;
            color: white;
            box-shadow: 0px 5px 15px rgba(0,0,0,0.6);
            .name{
                text-align: center;
                padding: 5px;
                background-color: #008ec9;
                font-size: 12px;
                .city{
                    color: #86c7f3;
                    font-size: 10px;
                }
            }
            .content{
                text-align: center;
                font-size: 12px;
                color: #000;
                padding: 15px;
                background-color: #b9ce36;
            }
        }
    }
`;

export default OpinionsStyle;