import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';

import Template from '../components/Template/Template';
import Stars from "../components/Opinion/Stars/Stars";
import Opinion from '../components/Opinion/Opinion';
import { mapKey } from "../actions/variables";
import style from '../sections/Map/style';
import api from "../actions/api";

import pinLogo from '../assets/images/pin-logo.png';
import OpinionsStyle from "../Styles/Opinions";

const center = {
    lat: 52.207603,
    lng: 20.890594
}
const zoom = 8;

const Opinions = () => {

    const [opinins,setOpinions] = useState([]);
    const [opinionsMap,setOpinionsMap] = useState([]);

    useEffect(() => {
        api('getOpinions',null,{ limit: 40 },r => {
            if (r.result){
                setOpinions(r.data.opinions.filter(obj => obj.rate>=3).map(op => {
                    op.name = op.name.split(' ')[0]
                    return op;
                }));
                setOpinionsMap(r.data.opinions.filter(obj => obj.rate>=3 && obj.city && obj.lat && obj.lon).map(op => {
                    op.name = op.name.split(' ')[0]
                    return op;
                }));
            }
        });
    },[]);

    // console.log('opininsMap: ',opinionsMap);

    return (
        <Template path="/opinie">

            <OpinionsStyle>
                <div className="mWidth">
                    <h1 className="ch1">Strefa zadowolonych klientów</h1>

                    {opinionsMap.length>0 &&
                        <div className="mapOpinions">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: mapKey }}
                                // defaultCenter={{ lat: opininsMap[opininsMap.length - 1].lat, lng: opininsMap[opininsMap.length - 1].lon }}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                options={{
                                    styles: style.mapStyle
                                }}
                            >

                                {opinionsMap.map(opinia => (
                                    <MarkerStyled
                                        key={`opinia_${opinia.id}`}
                                        lat={opinia.lat}
                                        lng={opinia.lon}
                                        name={opinia.name}
                                        content={opinia.content.length>30 ? opinia.content.substr(0,30)+'...' : opinia.content}
                                        city={opinia.city}
                                        rate={opinia.rate}
                                        id={opinia.id}
                                    />
                                ))}


                            </GoogleMapReact>
                        </div>
                    }

                    <section id="pSell" className="opinions">
                        {opinins.map(obj => (
                            <Opinion 
                                key={`opinion_${obj.id}`}
                                data={obj}
                            />
                        ))}
                    </section>
                </div>
            </OpinionsStyle>
    
        </Template>
    )
}

export default Opinions


const MarkerStyled = ({ name, content, city, rate, id }) => {

    const handleClick = () => {
        const element = document.querySelector('#opinion-'+id);
        if (element){
            console.log('element.scrollTop: ',element.scrollTop);
            window.scroll({
                top: element.getBoundingClientRect().top,
                behavior: 'smooth',
            })
        }
    }

    return (
        <div className="markerOpinion" onClick={handleClick}>
            <div className="name">
                {name}
                {/* <div className="city">{city}</div> */}
                {content &&
                    <div className="city">{content}</div>
                }
                <Stars stars={rate} small />
            </div>
            
        </div>
    )
};
