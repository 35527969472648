import React , { useState, useEffect } from 'react';
import StarsStyle from './StarsStyle';
import { TiStarOutline as StarDefault, TiStarFullOutline as StarFill } from 'react-icons/ti';

const maxStars = 4; 

const Stars = ({ stars , setCatName, small = false }) => {

    return (
        <StarsStyle small={small}>
            <ul>
                {[1,2,3,4,5].map(el => (
                    <li 
                        key={`li_${el}`} 
                        className={((stars>=el) ? ' fillStay' : '')} 
                    >

                            {(stars>=el) ? <StarFill /> : <StarDefault />}
                    
                    </li>
                ))}
            </ul>
        </StarsStyle>
    );
}
export default Stars;