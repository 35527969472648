import React , { useState, useEffect, useContext } from 'react';

import OpinionStyle from './OpinionStyle';
import Stars from './Stars/Stars';

const Opinion = ({ data }) => {

    return (
        <OpinionStyle id={`opinion-${data.id}`}>
            <div className="name">
                {data.name}
                <span className="date">{data.date}</span>
            </div>
            <div className="stars">
                <Stars stars={data.rate} />
            </div>
            <div className="content">{data.content}</div>
        </OpinionStyle>
    );
}
export default Opinion;