import styled from 'styled-components';
import { viewSd } from '../../../actions/variables';

const StarsStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    ul{
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0px;
        padding: 0px;
        li{
            flex: 1;
            padding: 0px;
            margin: 0px;
            cursor: pointer;
            svg{
                width: ${props => props.small ? 12 : 40}px;
                height: ${props => props.small ? 12 : 40}px;
                path{
                    fill: grey;
                }
            }
            &.fill svg path{
                fill: grey;
            }
            &.fillStay svg path{
                fill: #b8ce2d;
            }
        }
    }
    @media (max-width: ${viewSd}px){
        ul{
            width: 100%;
            li{
                text-align: center;
            }
        }
    }
`;	

export default StarsStyle;